<template>
  <UserTable></UserTable>
</template>

<script>
import UserTable from '../../components/User/Table'

export default {
  components: {
    UserTable
  }
}
</script>

<style>
</style>
