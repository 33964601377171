<template>
  <v-container>
    <v-row>
      <v-col md="6">
        <v-text-field
          v-model="nome"
          label="Nome"
          :rules="rules.required"
        ></v-text-field>
      </v-col>
      <v-col md="6">
        <v-text-field
          v-model="email"
          label="E-mail"
          :rules="rules.email"
          :disabled="isEdit"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="!isEdit">
      <v-col md="6">
        <v-select
          no-data-text="Nenhum dado encontrado"
          v-model="role_id"
          :items="roles"
          label="Perfil de acesso"
          :rules="rules.required"
          :loading="loadingRolesList"
        ></v-select>
      </v-col>
      <v-col md="6" v-if="isImobiliaria && isGSuser">
        <v-select
          no-data-text="Nenhum dado encontrado"
          v-model="cliente_id"
          :items="clients"
          label="Imobiliária"
          :rules="rules.required"
          :loading="loadingRealStateList"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="!isEdit || showPassword">
      <v-col md="6">
        <v-text-field
          v-model="senha"
          label="Senha"
          :rules="rules.password"
          :append-icon="showPass1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPass1 ? 'text' : 'password'"
          @click:append="showPass1 = !showPass1"
        ></v-text-field>
      </v-col>
      <v-col md="6">
        <v-text-field
          v-model="confirmacao_senha"
          label="Repita a senha"
          :rules="passwordConfirmationRule"
          :append-icon="showPass2 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPass2 ? 'text' : 'password'"
          @click:append="showPass2 = !showPass2"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { email } from '@/methods/validationRules'

export default {
  name: 'UserForm',
  props: {
    showPassword: {
      type: Boolean,
      default: false
    },
    isPerfil: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showPass1: false,
    showPass2: false,
    rules: {
      required: [(v) => !!v || 'Campo obrigatório'],
      email: [email, [(v) => !!v || 'Campo obrigatório']],
      password: [
        [(v) => !!v || 'Campo obrigatório'],
        (v) => v.length >= 8 || 'Mínimo de 8 caracteres'
      ]
    },
    loadingRealStateList: false,
    loadingRolesList: false
  }),
  created () {
    if (this.isGSuser) {
      this.loadingRealStateList = true
      this.getRealStateSelectList()
        .catch(err => {
          this.$toastr.e(err.response.data.message)
        })
        .finally(() => {
          this.loadingRealStateList = false
        })
    } else {
      this.cliente_id = this.activeUser.client_id
    }

    this.loadingRolesList = true
    this.getRolesSelectList()
      .catch(err => {
        this.$toastr.e(err.response.data.message)
      })
      .finally(() => {
        this.loadingRolesList = false
      })
  },
  computed: {
    ...mapGetters([
      'realStateSelectList',
      'isGSuser',
      'activeUser',
      'rolesSelectList'
    ]),
    ...mapFields([
      'user.id',
      'user.nome',
      'user.email',
      'user.senha',
      'user.confirmacao_senha',
      'user.role_id',
      'user.cliente_id'
    ]),
    passwordConfirmationRule () {
      return [
        [(v) => !!v || 'Campo obrigatório'],
        (v) => v === this.senha || 'As senhas não combinam',
        (v) => v.length >= 8 || 'Mínimo de 8 caracteres'
      ]
    },
    isImobiliaria () {
      return this.role_id == 4 || this.role_id == 5
    },
    isEdit () {
      return this.id != null
    },
    clients () {
      return this.realStateSelectList
    },
    roles () {
      return this.rolesSelectList
    }
  },
  methods: {
    ...mapActions(['getRealStateSelectList', 'getRolesSelectList'])
  }
}
</script>
