<template>
  <v-dialog v-model="dialog" max-width="40%" @click:outside="closeDialog">
    <template v-slot:activator="{ on, attrs }">
      <v-row>
        <v-col md="4" v-if="!isPerfil">
          <v-row>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar..."
              single-line
              hide-details
            ></v-text-field>
          </v-row>
        </v-col>
        <v-col align="right" v-if="!readonly">
          <v-btn
            v-if="!isPerfil"
            color="primary"
            dark
            class="mb-2"
            v-bind="attrs"
            v-on="on"
            rounded
          >
            Novo usuário
          </v-btn>
          <v-btn v-else small v-bind="attrs" v-on="on"> Perfil </v-btn>
        </v-col>
      </v-row>
    </template>
    <v-form v-model="valid">
      <v-card v-if="dialog">
        <v-card-title>
          <v-row>
            <v-col>
              <h5 class="primary--text bold">{{ itemId ? "Usuário" : "Novo usuário" }}</h5>
            </v-col>
            <v-col align="right">
              <v-btn color="black" right icon dark @click="closeDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text v-if="loading">
          <v-container>
            <v-row justify="center">
              <v-progress-circular
                :size="70"
                :width="7"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-text v-else class="pa-2">
          <v-container>
            <v-row justify="center">
              <UserForm
                :showPassword="showPassword"
                :isPerfil="isPerfil"
              ></UserForm>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="isEdit"
            color="error"
            outlined
            text
            @click="setShowPassword"
            rounded
          >
            {{ isPerfil ? "Editar senha" : "Resetar senha" }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="" @click="dialog = false" rounded> Fechar </v-btn>
          <v-btn
            color="primary"
            @click="save"
            :loading="saving"
            :disabled="!valid"
            rounded
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import UserForm from './Forms/User'

export default {
  name: 'RealEstateFormDialog',
  components: {
    UserForm
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    itemId: {
      type: Number,
      default: null
    },
    isPerfil: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialog: false,
    saving: false,
    loading: false,
    search: null,
    showPassword: false,
    valid: false
  }),
  computed: {
    isEdit () {
      return this.itemId != null
    }
  },
  watch: {
    itemId (val) {
      if (this.exists(val)) {
        this.dialog = true
      }
    },
    dialog (val) {
      if (val) {
        this.resetUser()
        if (this.exists(this.itemId)) {
          this.loading = true
          this.getUser(this.itemId)
            .catch(err => {
              this.$toastr.e(err.response.data.message)
            })
            .finally(() => {
              this.loading = false
            })
        }
      }
    },
    search (val) {
      this.$emit('input', val)
    }
  },
  methods: {
    ...mapMutations(['resetUser']),
    ...mapActions(['saveUser', 'getUser']),
    save () {
      this.saving = true
      this.saveUser()
        .then(() => {
          this.resetUser()
          this.$emit('reloadList')
          this.closeDialog()
        })
        .catch(err => {
          this.$toastr.e(err.response.data.message)
        })
        .finally(() => {
          this.saving = false
        })
    },
    closeDialog () {
      this.resetUser()
      this.$emit('cleanData')
      this.dialog = false
      this.showPassword = false
      return true
    },
    setShowPassword () {
      this.showPassword = !this.showPassword
    }
  }
}
</script>
