export const email = function(val) {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return pattern.test(val) || 'Formato de e-mail inválido'
}

export const password = function(val) {
  const containsUppercase = /[A-Z]/.test(val)
  const containsLowercase = /[a-z]/.test(val)
  const containsNumber = /[0-9]/.test(val)
  const containsSpecial = /[#?!@$%^&*-]/.test(val)
  return containsUppercase && containsLowercase && containsNumber && containsSpecial
}


