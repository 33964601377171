<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" title="Excluir">
        <v-icon small color="error"> mdi-delete </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text class="pl-2 pr-2 pt-1 pb-1">
        <v-container>
          <v-row>
            <v-col class="pa-0 ma-0">
              <h5 class="bold">Tem certeza de que deseja excluir esse registro? <br>Esta ação não poderá ser desfeita!</h5>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color=""
          @click="dialog = false"
          rounded
        >
          Cancelar
        </v-btn>
        <v-btn
          color="error"
          @click=deleteRegister
          :loading=loading
          rounded
        >
          Excluir
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'UserDelete',
  props: {
    itemId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    loading: false
  }),
  methods: {
    ...mapActions(['deleteUser']),
    deleteRegister () {
      this.loading = true
      this.deleteUser(this.itemId)
        .catch(err => {
          this.$toastr.e(err.response.data.message)
        })
        .finally(() => {
          this.loading = false
          this.dialog = false
        })
    }
  }
}
</script>
